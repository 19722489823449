import defaultTheme from 'tailwindcss/defaultTheme';
import { EmbedOptions } from 'vega-embed';
import { SYSLOG_SEVERITY, PRETTY_SYSLOG_SEVERITY } from '../types/syslog.types';

/**
 * DSA Hub title
 */
export const DSA_HUB = 'DSA Hub';

/**
 * Styleguide and tutorial colors
 */
export const COLORS = {
  brand: {
    name: 'Brand Colors',
    colors: {
      brandGreen: { name: 'brand-green', color: '#11A63D' },
      brandYellow: { name: 'brand-yellow', color: '#F5CC02' },
      brandBlue: { name: 'brand-blue', color: '#3BC0F8' },
    },
  },
  tint: {
    name: 'Tint Colors',
    colors: {
      brandMedTintGreen: { name: 'brand-med-tint-green', color: '#89D49F' },
      brandMedTintYellow: { name: 'brand-med-tint-yellow', color: '#FAE580' },
      brandMedTintBlue: { name: 'brand-med-tint-blue', color: '#9DDFFB' },
      brandTintGreen: { name: 'brand-tint-green', color: '#D0EED8' },
      brandTintYellow: { name: 'brand-tint-yellow', color: '#FDF4CC' },
      brandTintBlue: { name: 'brand-tint-blue', color: '#D7F2FD' },
    },
  },
  shade: {
    name: 'Shade Colors',
    colors: {
      brandMedShadeGreen: { name: 'brand-med-shade-green', color: '#008427' },
      brandMedShadeYellow: { name: 'brand-med-shade-yellow', color: '#E9AB00' },
      brandMedShadeBlue: { name: 'brand-med-shade-blue', color: '#0095FF' },
      brandShadeGreen: { name: 'brand-shade-green', color: '#005D1F' },
      brandShadeYellow: { name: 'brand-shade-yellow', color: '#D68000' },
      brandShadeBlue: { name: 'brand-shade-blue', color: '#0060C6' },
    },
  },
  ui: {
    name: 'UI Colors',
    colors: {
      brandBlack: { name: 'brand-black', color: '#262626' },
      brandShadeGrey: { name: 'brand-shade-grey', color: '#6E6E6E' },
      brandGrey: { name: 'brand-grey', color: '#9FA4AA' },
      brandWhite: { name: 'brand-white', color: '#FFFFFF' },
      brandTintGrey3: { name: 'brand-tint-grey-3', color: '#D6DAE0' },
      brandTintGrey2: { name: 'brand-tint-grey-2', color: '#E8EEF1' },
      brandTintGrey1: { name: 'brand-tint-grey-1', color: '#F1F5F7' },
      brandRed: { name: 'brand-red', color: '#DC1F00' },
      brandRedHover: { name: 'brand-red-hover', color: '#AA1800' },
      brandTintRed: { name: 'brand-tint-red', color: '#FEF8FB' },
    },
  },
} as const;

/**
 * Media queries
 */
export const MEDIA_QUERIES = {
  MOBILE_XS: '(min-width: 375px)', // custom breakpoint in `tailwind.config.js`
  DESKTOP: `(min-width: ${defaultTheme.screens.lg})`,
  DESKTOP_XL: `(min-width: ${defaultTheme.screens.xl})`,
  LANDSCAPE: '(orientation: landscape)',
};

/**
 * Application roles
 */
export enum ROLES {
  DEVELOPER = 'DEVELOPER',
  ADMIN = 'ADMIN',
  CORPORATE = 'CORPORATE',
  FIELD_COMPANY = 'FIELD_COMPANY',
  FIELD_FRANCHISE = 'FIELD_FRANCHISE',
  NO_REPORTING = 'NO_REPORTING',
}

/**
 * Path segments
 */
export const PATH_SEGMENTS = {
  browse: '/browse',
  content: '/content',
  dsaInsights: '/dsa-insights',
} as const;

/**
 * Paths
 */
export const BASE_PATHS = {
  home: '/',
  searchResults: '/search',
  favorites: '/favorites',
  browse: `${PATH_SEGMENTS.browse}/*`,
  notifications: '/notifications',
  faqs: '/faqs',
  content: `${PATH_SEGMENTS.content}/:slug`,
} as const;

export const DSA_INSIGHTS_PATHS = {
  dsaInsightsFeed: PATH_SEGMENTS.dsaInsights,
  dsaInsightsPost: `${PATH_SEGMENTS.dsaInsights}/:slug`,
} as const;

export const OTHER_PATHS = {
  styleguide: '/styleguide',
  dev: {
    dsaInsights: `/dev${PATH_SEGMENTS.dsaInsights}`,
  },
  dsaTeams: '/dsa-teams',
  settings: '/settings',
  adminPortal: '/admin-portal',
  aiChat: '/ai-chat',
} as const;

export const PATHS = {
  ...BASE_PATHS,
  ...DSA_INSIGHTS_PATHS,
  ...OTHER_PATHS,
} as const;

export const WILDCARD = '*';

/**
 * Navigation
 */
export enum PAGE_TITLES {
  HOME = 'Home',
  BROWSE = 'Browse',
  FAVORITES = 'Favorites',
  DSA_INSIGHTS = 'DSA Insights',
  DSA_TEAMS = 'DSA Teams',
  NOTIFICATIONS = 'Notifications',
  FAQS = 'FAQs',
  TUTORIAL = 'Tutorial',
  ADMIN_PORTAL = 'Admin Portal',
  SETTINGS = 'Settings',
  LOGOUT = 'Logout',
  AI_CHAT = 'AI Chat',
}

export const NAV = {
  SLIDER_BUTTON: 'nav-slider-button',
  SLIDER: 'nav-slider',
} as const;

export const LOOKER_SESSION_PARAM = 'lookerSession=true';

/**
 * Root folder for browse route
 */
export const BROWSE_ROUTE_ROOT_FOLDER = 'All Content';

/**
 * Scroll containers
 */
export const SCROLL_CONTAINER = {
  FAVORITES: 'favorites-scroll-container',
  POPULAR_CONTENT: 'popular-content-scroll-container',
  POPULAR_TAGS: 'popular-tags-scroll-container',
  UNSELECTED_TAGS: 'unselected-tags-scroll-container',
} as const;

/**
 * Overflow gradient classes
 */
export const OVERFLOW_GRADIENT =
  'after:absolute after:bottom-0 after:left-0 after:right-0 after:pointer-events-none after:h-24 after:bg-gradient-to-b after:from-brand-tint-grey-1/0 after:to-brand-tint-grey-1';

/**
 * Notifications
 */
export const NOTIFICATIONS_SCROLL_CONTAINER = 'notifications-scroll-container';
export const NO_NEW_ACTIVITY = 'No new activity';
export const OPEN_NOTIFICATIONS = 'Open notifications';
// Notification roles
export enum NOTIFICATION_ROLES {
  CORPORATE = 'Corporate',
  FIELD_COMPANY = 'Field Company',
  FIELD_FRANCHISE = 'Field Franchise',
}
export const NOTIFICATIONS = 'Notifications';
export const NOTIFICATIONS_TITLE = 'notifications-title';
export const MARK_ALL_AS_READ = 'Mark all as read';
export const MARK_ALL_AS_UNREAD = 'Mark all as unread';
export const MARK_AS_READ = 'Mark as read';
export const MARK_AS_UNREAD = 'Mark as unread';
export const DATA_DISMISS_BTN = '[data-dismissbtn]';

/**
 * Spinner
 */
export enum SPINNER_SIZES {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export const SPINNER_CLASSES = {
  LARGE: 'h-16 w-16',
  MEDIUM: 'h-12 w-12',
  SMALL: 'h-8 w-8',
} as const;

/**
 * State
 */
export enum STATE_STATUSES {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum CARD_TITLES {
  FAVORITES = 'Favorites',
  POPULAR_CONTENT = 'Popular content',
  DSA_INSIGHTS = 'DSA insights',
  POPULAR_TAGS = 'Popular tags',
}

/**
 * Events and keys
 */
export enum EVENTS {
  KEYDOWN = 'keydown',
  KEYUP = 'keyup',
  CLICK = 'click',
  SCROLL = 'scroll',
  MESSAGE = 'message',
  RESIZE = 'resize',
}

// Looker events
export enum LOOKER_EVENTS {
  DASHBOARD_LOADED = 'dashboard:loaded',
  PAGE_PROPERTIES_CHANGED = 'page:properties:changed',
}

export enum KEYS {
  ESCAPE = 'Escape',
  TAB = 'Tab',
  ENTER = 'Enter',
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
}

/**
 * Empty state messages
 */
export enum EMPTY_STATE_MESSAGES {
  NO_FAVORITES = 'You currently have no favorites.',
  NO_POPULAR_CONTENT = 'There is currently no popular content.',
  NO_DSA_INSIGHTS = 'There are currently no DSA insights.',
  NO_POPULAR_TAGS = 'There are currently no popular tags.',
}

/**
 * FAQs headings
 */
export enum FAQS_HEADINGS {
  HEADING = 'FAQs',
  REJECTED = 'FAQs cannot be displayed.',
  NO_FAQS = 'There are currently no FAQs.',
}

/**
 * DSATeams headings
 */
export enum DSA_TEAMS_COPY {
  HEADING = 'DSA Teams',
  REJECTED = 'DSA Teams cannot be displayed.',
  NO_DSA_TEAMS = 'There are currently no DSA Teams.',
  NOT_AUTHORIZED = 'You are currently not authorized to view the Hub.',
  INSTRUCTIONS = 'Follow these instructions to gain access.',
}

/**
 * Errors
 *
 */
// Error codes
// NOTE: must be of type string because AxiosError casts `error.code` as a string
export const ERROR_CODES = {
  BAD_REQUEST: '400',
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  INTERNAL_SERVER_ERROR: '500',
  SERVICE_UNAVAILABLE: '503',
};

// Axios `AbortController.abort` cancel error
export const CANCELED_ERROR = 'CanceledError';

// CustomEvents
export const ERROR_BOUNDARY_EVENT = 'error-boundary-event';

/**
 * Searchbar and SearchSuggestions
 */
export const SEARCH_INPUT = 'search-input';
export const SEARCHING = 'Searching...';
export const NO_SUGGESTIONS_FOUND = 'No suggestions found';
export const FORM = 'form';

/**
 * Debounce timeout
 */
export const DEBOUNCE_TIMEOUT = 150;

/**
 * Looker reauthentication buffer
 */
export const LOOKER_REAUTHENTICATION_BUFFER = 1000 * 60 * 10;

/**
 * Fuse options
 */
export const fuseQueryAndTagsOptions = {
  includeScore: true,
  threshold: 0.2,
  distance: 1000,
  keys: [
    { name: 'title', weight: 0.9 },
    { name: 'tags.name', weight: 0.1 },
  ],
};

export const fuseAdminTagsTabOptions = {
  ...fuseQueryAndTagsOptions,
  keys: [
    { name: 'title', weight: 0.8 },
    { name: 'idFromSource', weight: 0.1 },
    { name: 'tags.name', weight: 0.1 },
  ],
};

export const fuseAdminFavoritesOptions = {
  ...fuseQueryAndTagsOptions,
  keys: [
    { name: 'title', weight: 0.8 },
    { name: 'idFromSource', weight: 0.1 },
    { name: 'source.name', weight: 0.1 },
  ],
};

export const fuseAdminFavoritesUsersOptions = {
  ...fuseQueryAndTagsOptions,
  keys: [
    { name: 'lastName', weight: 0.6 },
    { name: 'firstName', weight: 0.3 },
    { name: 'email', weight: 0.1 },
  ],
};

export const fuseTagsOptions = {
  ...fuseQueryAndTagsOptions,
  keys: ['tags.name'],
};

export const fuseAdminTagsOptions = {
  ...fuseQueryAndTagsOptions,
  keys: ['name'],
};

/**
 * ContentCard sizes and background colors
 */
export enum CONTENT_CARD_SIZES {
  SMALL = 'lg:w-1/4',
  LARGE = 'mb-4 h-52 md:mb-0',
}

export enum CONTENT_CARD_BACKGROUND_COLORS {
  BRAND_WHITE = 'bg-brand-white',
  BRAND_TINT_GREY_1 = 'bg-brand-tint-grey-1',
}

/**
 * Content types
 */
export enum CONTENT_TYPES {
  DASHBOARD = 'Dashboard',
  VIDEO = 'Video',
  APP = 'App',
}

/**
 * Search param types
 */
export enum SEARCHPARAM_KEYS {
  SORT = 'sort',
  TAG = 'tag',
  QUERY = 'query',
  TAB = 'tab',
  ANALYTICS_TYPE = 'analyticsType',
  ANALYTICS_SEARCH_ROLE = 'analyticsSearchRole',
  ANALYTICS_SEARCH_FROM_DATE = 'analyticsSearchFromDate',
  ANALYTICS_SEARCH_THRU_DATE = 'analyticsSearchThruDate',
}

/**
 * Multipurpose variables
 */
export const CLOSE = 'Close';
export const CANCEL = 'Cancel';
export const CONFIRM = 'Confirm';
export const EPOCH = new Date(0).toISOString();

/**
 * Titles for dropdowns and buttons in `DesktopSortAndFilterContainer` and `ScreenTakeover`
 */
export const SORT_AND_FILTER_TITLES = {
  SORT_BY: 'Sort by',
  FILTER: 'Filter',
  FILTERING: 'Filtering...',
  FILTER_BY_TAGS: 'Filter by tags',
  FILTER_BY: 'Filter by',
  APPLY: 'Apply',
  DONE: 'Done',
  CLEAR_FILTERS: 'Clear filters',
  CLEAR_TAGS: 'Clear tags',
  RELEVANCE: 'Relevance',
} as const;

/**
 *  dropdown MENU and button
 */
export const DROPDOWN_MENU = 'dropdown-menu';

/**
 * FilterByTagsTakeover and FilterByTagsButton
 */
export const TAGS_SEARCH_INPUT = 'tags-search-input';
export const FITLER_BY_TAGS_TAKEOVER = 'filter-by-tags-takeover';
export const FILTER_BY_TAGS_BUTTON = 'filter-by-tags-button';
export const SEARCH_FOR_TAGS = 'Search for tags';

/**
 * `{replace: true}` constant for setting search params
 */
export const REPLACE = { replace: true };

/**
 *  axios header auth tokens
 */
export const X_MS_TOKEN_AAD_ID_TOKEN = 'x-ms-token-aad-id-token';
export const X_MS_TOKEN_AAD_ACCESS_TOKEN = 'x-ms-token-aad-access-token';

/**
 *  cookies instruction links
 */
export const COOKIE_INSTRUCTION_LINKS = [
  {
    title: 'Chrome',
    desktopLink:
      'https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop',
    mobileLink:
      'https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DAndroid&oco=1',
  },
  {
    title: 'Safari',
    desktopLink:
      'https://support.apple.com/en-in/guide/safari/ibrw850f6c51/mac',
    mobileLink:
      'https://cookie-script.com/knowledge-base/enable-cookies-iphone',
  },
  {
    title: 'Microsoft Edge',
    desktopLink:
      'https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52',
    mobileLink: 'https://cookie-script.com/knowledge-base/disable-cookies-edge',
  },
  {
    title: 'Firefox',
    desktopLink:
      'https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them',
    mobileLink:
      'https://cookie-script.com/knowledge-base/enable-cookies-firefox',
  },
];

/**
 * Tutorial
 */
export const TUTORIAL_TARGETS = {
  INTRO: 'tut-intro',
  FAVORITES_CARD: 'tut-favorites-card',
  NAV_BTN: NAV.SLIDER_BUTTON,
  NAV_CONTENT: 'tut-nav-content',
  NAV_SUPPORT: 'tut-nav-support',
  NOTIFICATIONS_BTN: 'tut-notifications',
  NOTIFICATIONS: NOTIFICATIONS_TITLE,
  SEARCH: SEARCH_INPUT,
} as const;

type TUTORIAL_TARGET_KEYS = keyof typeof TUTORIAL_TARGETS;
type TUTORIAL_TARGET_VALUES =
  `#${(typeof TUTORIAL_TARGETS)[keyof typeof TUTORIAL_TARGETS]}`;

export const TUTORIAL_TARGET_SELECTORS = (
  Object.keys(TUTORIAL_TARGETS) as TUTORIAL_TARGET_KEYS[]
).reduce((acc, cur) => {
  acc[cur] = `#${TUTORIAL_TARGETS[cur]}`;
  return acc;
}, {} as Record<TUTORIAL_TARGET_KEYS, TUTORIAL_TARGET_VALUES>);

export const LOOKER_SOURCE = 'looker';
/**
 * Admin Portal
 */
export const ADMIN = {
  FILTER_CONTENT: 'Filter content',
  SORT: 'Sort',
  SHARED: {
    CONTENT_ITEM: 'Content Item',
  },
  NOTIFICATIONS: {
    NAME: NOTIFICATIONS,
    ADD_NEW: 'Add New',
    ADD_NOTIFICATION: 'Add Notification',
    EDIT_NOTIFICATION: 'Edit Notification',
    ACTIVE_NOTIFICATION: 'Active Notifications',
    EXPIRED_NOTIFICATION: 'Expired Notifications',
    DELETE: 'Delete',
    SAVE: 'Save',
    EXPIRES_AT: 'Expires At',
    MESSAGE: 'Message',
    TITLE: 'Title',
    ROLES: 'Roles',
    SEVERITY: 'Severity',
    CONFIRM_DELETE: 'Are you sure you want to delete',
    REJECTED: 'Notifications cannot be displayed.',
    FORM_ID: 'notification-edit-form',
    DELETING_NOTIFICATION_MESSAGE: 'Deleting notification...',
    UPDATING_NOTIFICATION_MESSAGE: 'Updating notification...',
    CREATING_NOTIFICATION_MESSAGE: 'Creating notification...',
  },
  TAGS: {
    NAME: 'Tags',
    REJECTED: 'Tags cannot be displayed.',
    ALL_TAGS: 'All Tags',
    ALL_TAGS_ID: 'all-tags',
    DELETE: 'Delete tag',
    CONFIRM_DELETE: 'Are you sure you want to delete',
    FILTER_CONTENT: 'Filter content',
    NO_CONTENT_FOUND: 'No content found',
    CONTENT: 'Content',
    FILTER_TAGS: 'Filter tags',
    NO_TAGS_FOUND: 'No tags found',
    FETCH_TAG_ERROR: 'Tags cannot be displayed',
    DELETE_TAG_ERROR: 'Deletion failed. Please try again.',
    FORM_ID: 'tag-edit-form',
    ADD_NEW_TAG: 'Add New Tag',
    ADD_NEW_TAG_ID: 'add-new-tag',
    EDIT_TAGS: 'Edit Tags',
    CURRENT_TAGS: 'Current Tags',
    UPDATE_TAG_ERROR: 'Updation failed. Please try again.',
  },
  LOOKER: {
    NAME: 'Looker',
    SYNC_BTN: 'Sync dashboards',
    CHECK_STATUS_BTN: 'Check status',
    SYNC_IN_PROGRESS: 'Sync is in progress...',
    CHECK_STATUS_MESSAGE: 'Checking sync status...',
    ALREADY_IN_PROGRESS: 'Another admin is currently syncing.',
    ERROR_MESSAGE: 'Something went wrong, please reload.',
  },
  ANALYTICS: {
    NAME: 'Analytics',
    SELECT_TYPE: 'Select Type',
    USER_INFO: 'User Info',
    FAVORITES: {
      NAME: 'Favorites',
      REJECTED: 'Favorites analytics cannot be displayed.',
      FILTER_USERS: 'Filter users',
      FAVORITE_USERS: 'Favorite users',
      FAVORITE_USERS_ID: 'content-users-id',
      FAVORITE_CONTENT: 'Favorite Content',
      USERS: 'Users',
      NO_CONTENT_FOUND: 'No content found',
      INFO_DESCRIPTION:
        'Shows favorited DSA Hub content items by count. Tap or click a content item to show the users who have favorited it.',
      NO_USER_FOUND: 'No user found',
    },
    SEARCH: {
      NAME: 'Search',
      SEARCH_ANALYTICS: 'Search Analytics',
      SEARCH_ANALYTICS_FILTER: 'search-analytics-filter',
      KEYWORDS: 'Keywords',
      INFO_DESCRIPTION:
        'Shows data from DSA Hub searches, with a 25% sampling rate. Tap or click a widget to expand the list. Note: Defaults to last 30 days.',
      LIST_MODAL: 'list-modal',
      ROLES: 'Roles',
      DATE_RANGE: 'Date range',
      FROM_DATE: 'From Date',
      THRU_DATE: 'Thru Date',
      APPLY: 'Apply',
      NO_SEARCH_DATA: 'No search analytics',
      REJECTED: 'Search analytics cannot be displayed.',
      N_GRAM: 'N-Grams',
      CONTENT_ENGAGEMENT: 'Content Engagement',
      PARENT_FOLDERS: 'Parent Folders',
      OPEN_DASHBOARD: 'Open dashboard',
      MAINTENANCE_MESSAGE:
        'Search analytics is temporarily down for maintenance.',
    },
  },
};

// notification severity tuples in alphabetical order
export const syslogSeverities: [SYSLOG_SEVERITY, PRETTY_SYSLOG_SEVERITY][] = [
  [SYSLOG_SEVERITY.INFO, PRETTY_SYSLOG_SEVERITY.INFO],
  [SYSLOG_SEVERITY.ISSUE, PRETTY_SYSLOG_SEVERITY.ISSUE],
  [SYSLOG_SEVERITY.RESOLVED, PRETTY_SYSLOG_SEVERITY.RESOLVED],
];

// 10 minutes (in milliseconds) for application state updates `setInterval`
export const APPLICATION_STATE_UPDATES_INTERVAL = 1000 * 60 * 10;

// app updates
export const UPDATE_ALERT_MESSAGE =
  "Update Available: We've made some fixes and improvements. To complete the process, please refresh the browser.";

/**
 * AI Chat
 */
export const AI_CHAT = {
  ERRORS: {
    GLOBAL: 'A server error has occured. Please try again.',
  },
  CHATS: {
    CHATS: 'Chats',
    NEW_CHAT: 'New Chat',
    MOBILE_CHATS_MASKING_CONTAINER: 'mobile-chats-masking-container',
    CHATS_TITLE: 'chats-title',
  },
  MESSAGES: {
    AVATAR_LABEL: {
      LLM: 'AI',
    },
    GREETING_MESSAGE:
      'Hi there! I am an AI enabled assistant. Please keep in mind that I have not been trained on non-public H&R Block information so my responses will not incorporate such information! How can I help you today?',
    MESSAGES_ERROR:
      'Something went wrong on our end. The error has been recorded for troubleshooting.',
    AI_MESSAGE_ERROR_PLACEHOLDER: ' ',
    LLM_ERROR_MSG:
      'Apologies that I was unable to correctly process your inquiry. Please try again.',
    LIMIT_REACHED: 'Limit reached. Please start a new chat.',
  },
  INPUT: {
    PLACEHOLDER: 'Send a message',
    POST_PENDING: 'Thinking...',
    LIMIT_PLACEHOLDER: 'Limit reached.',
  },
  FEEDBACK: {
    PROMPTS: {
      THUMBS_UP: 'What do you like about the response?',
      THUMBS_DOWN: 'What is the issue with the response?',
    },
    VALUES: {
      THUMBS_UP: 'Thumbs up',
      THUMBS_DOWN: 'Thumbs down',
    },
  },
  FOOTER: {
    CLEAR_CHATS: 'Clear chats',
    INFORMATION: 'Information',
    INFORMATION_ID: 'information',
    CONFIRM_DELETE_ALL: 'Are you sure you want to delete all chats?',
    DELETE_ALL_CHAT_ERROR: 'Deletion failed. Please try again.',
  },
  VIZ: {
    NO_VIZ_LIBRARY_ERROR: 'No visualization library selected',
  },
  MODALS: {
    TEST_ID: 'modal-cta',
    CONFIRMATION: {
      ID: 'ai-chat-confirmation',
      ARIAL_LABEL: 'Confirmation Modal',
      BUTTON_LABEL1: 'Cancel',
      BUTTON_LABEL2: 'OK',
    },
    PROMPT: {
      ID: 'ai-chat-prompt',
      ARIAL_LABEL: 'Prompt Modal',
      MESSAGE: 'Enter a new title.',
      BUTTON_LABEL1: 'Cancel',
      BUTTON_LABEL2: 'OK',
    },
    ALERT: {
      ID: 'ai-chat-alert',
      ARIAL_LABEL: 'Alert Modal',
      BUTTON_LABEL: 'OK',
    },
  },
};

// options for vega-embed library
export const vegaEmbedOptions: EmbedOptions = {
  mode: 'vega-lite',
  actions: false, // hide the actions button
  theme: 'excel',
  config: {
    autosize: {
      type: 'fit',
      resize: true,
      contains: 'padding',
    },
  },
};
