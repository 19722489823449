import { ROLES } from '../utils/constants';
import { Config } from '../state/config/config.slice';
import { SYSLOG_SEVERITY } from './syslog.types';
import { Content } from './content.types';
import { ObjectIdString, WithObjectId } from '.';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  settings: UserSettings;
  favorites: ObjectIdString[];
  pinnedFavorites: ObjectIdString[];
  unreadNotifications: WithObjectId<INotification>[];
  readNotifications: WithObjectId<INotification>[];
  role: ROLES;
  isAIChatUser: boolean;
  isTutorialComplete: boolean;
  lastLoggedIn: string;
  banner: UserBanner;
}

export interface UserSettings {
  dashboardLayout: [
    [DashboardLayoutOptions, DashboardLayoutOptions],
    [DashboardLayoutOptions, DashboardLayoutOptions]
  ];
}

export enum DashboardLayoutOptions {
  FAVORITES = 'FAVORITES',
  DSA_INSIGHTS = 'DSA_INSIGHTS',
  POPULAR_CONTENT = 'POPULAR_CONTENT',
  POPULAR_TAGS = 'POPULAR_TAGS',
}

interface UserBanner {
  dateAcknowledged: string; // ISO string
}

export interface INotification {
  title: string;
  message: string;
  createdAt: string;
  severity: SYSLOG_SEVERITY;
}

export interface NotificationsDoc {
  unreadNotifications: WithObjectId<INotification>[];
  readNotifications: WithObjectId<INotification>[];
}

export interface UserInFlightRequests {
  favorites: Record<ObjectIdString, true>;
  pinnedFavorites: Record<ObjectIdString, true>;
  readNotifications: Record<ObjectIdString, true>;
  unreadNotifications: Record<ObjectIdString, true>;
}

export interface ApplicationStateUpdatesResponse {
  notifications: {
    readNotifications: WithObjectId<INotification>[] | null;
    unreadNotifications: WithObjectId<INotification>[] | null;
  };
  config: WithObjectId<Config> | null;
  content: WithObjectId<Content>[] | null;
  popularContent: ObjectIdString[] | null;
  popularTags: ObjectIdString[] | null;
}
